import Select from 'react-select';
import Creatable from 'react-select/creatable';
import React, {useEffect, useState} from "react";
import {FormGroup, Label, UncontrolledTooltip} from 'reactstrap';
import {reportTypes} from "../docs/data";


export function MultiSelect({groupName, formName, name, state, setState, qboAccount, tooltipContent}) {
    const [options, setOption] = useState([])
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue('')
    }, [qboAccount])

    useEffect(() => {
        let enabledReports = state['report_types'] ? state['report_types'] : []
        if (state.integration_name === 'COVA') {
            enabledReports = enabledReports.filter(x => x !== 'DEBIT')
        }
        const currentOptions = reportTypes.filter((x) => enabledReports.includes(x.value))
        setOption(currentOptions)
    }, [state.report_types])

    const handleInput = (event) => {
        let choices = event.map(x => x.value)
        state[name] = choices
        setState({...state})
        setValue(event)
    }
    //
    // const handleChange = (selected) => {
    //     if (selected && selected.length) {
    //         // If the user created a new option
    //         const lastOption = selected[selected.length - 1];
    //         if (lastOption.__isNew__) {
    //             console.log('New option created:', lastOption);
    //         }
    //     }
    //     setSelectedOptions(selected);
    // };

    return (
        <FormGroup>
            <Label for={groupName} id={name} targetId={name}>
                {formName}
            </Label>
            <Select
                isMulti
                name="colors"
                value={value}
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleInput}
            />
            <UncontrolledTooltip placement="top" target={name}>
                {tooltipContent}
            </UncontrolledTooltip>
        </FormGroup>
    );
}

export const MultiSelectCreatable = ({groupName, formName, name, state, setState, qboAccount, tooltipContent}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (state[name]) {
            console.log('currState', state[name])
            let vals = state[name]
            if (typeof state[name] === 'string') vals = state[name].split(',')
            let opt = vals.map(x => ({value: x, label: x}))
            setSelectedOptions(opt)
        }
    }, [qboAccount, state[name]])

    // Options that can be pre-selected or provided
    const initialOptions = [];

    const handleChange = (selected) => {
        console.log('remove', selected)
        let opt = selected.map(x => x.value)
        console.log('choices', opt)

        setState({...state, [name]: opt})
    };

    return (
        <FormGroup>
            <Label for={groupName} id={name} targetId={name}>
                {formName}
            </Label>
            <Creatable
                isMulti
                value={selectedOptions}
                options={initialOptions}
                onChange={handleChange}
                placeholder="Create and hit Enter..."
                isClearable
            />
            <UncontrolledTooltip placement="top" target={name}>
                {tooltipContent}
            </UncontrolledTooltip>
        </FormGroup>
    );
};
