const timeZones = ['US/Pacific','US/Mountain','US/Central','US/Eastern','US/Alaska','US/Hawaii']

const integrationNames = ['DUTCHIE', 'FLOWHUB', 'COVA', 'CANIX']

const defaultDebitTypes = {
    'DUTCHIE': {
        'debitPaid': 'Debit Clearing Account',
        'checkPaid': 'Check Clearing Account',
        'creditPaid': 'Credit Clearing Account',
        'giftPaid': 'GiftCard Clearing Account',
        'prePaymentAmount': 'Dutchie Pay Clearing Account'
    },
    'FLOWHUB': {
        'debit': 'Debit Clearing Account',
        'giftcard': 'Debit Clearing Account'
    },
    'COVA': {
        'Debit Payment': 'Debit Clearing Account',
        'Integrated Card Payment': 'Integrated Card Clearing Account'
    }
}


const inventoryCategoryStatus = 'inventoryCategoryStatus'
const userSettings = {'': {integration_name: undefined}}
const userSettingsCovaDemo = {'': {integration_name: 'COVA', is_demo: true}}


const accountingBasis = ['Accrual', 'Cash']

const reportTypes = [
    { value: 'TRANSACTION', label: 'Vault Transfers'},
    { value: 'INVENTORY', label: 'Bills'},
    { value: 'INCOME', label: 'Sales & Refunds'},
    { value: 'MIMO_CASHDROP', label: 'Mimo Cashdrop'},
    { value: 'MIMO_VENDOR_PAYOUT', label: 'Mimo Vendor Payouts'}
]

const getUrl = (endpointName) => {
    return 'https://' + window.location.hostname + '/' + endpointName
}
export {userSettings, userSettingsCovaDemo, integrationNames, timeZones, getUrl, inventoryCategoryStatus, accountingBasis, defaultDebitTypes, reportTypes}