import React, {useState, useEffect} from "react";
import JsonToTable from "./table";
import {Button, Collapse} from "reactstrap";
import {reportTypes} from "../docs/data";
import {checkWarnings, convertDateFormat} from "../service/utils";


function RunReportDetails({state, qboAccount}) {
    const [table, setTable] = useState([])
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setIsOpen(false)
    }, [qboAccount])

    const getReportNameMap = () => {
        let map = {}
        for (let obj of reportTypes) {
            map[obj.value] = obj.label
        }
        return map
    }
    const reportNameMap = getReportNameMap()

    useEffect(() => {
        const runLock = JSON.parse(state['run_lock'])
        const reportStr = runLock['lambda_name'] === 'remove_reports' ? state['remove_run_result'] : state['historical_run_result']
        const report = reportStr ? JSON.parse(reportStr) : ''

        const parseReports = () => {

            let result = []
            for (const date in report) {
                let row = {'Report Date': convertDateFormat(date)}
                for (const reportName in report[date]) {
                    let runReport = report[date][reportName]
                    let prettyReportName = reportNameMap[reportName]
                    row[prettyReportName] = runReport.fails > 0 ? 'failed' : 'succeeded'
                }
                result.push(row)
            }
            return result
        }
        let parsedData = parseReports()
        setTable(parsedData)
    }, [qboAccount])

    return (
        <div>
            <Button color="success" onClick={() => setIsOpen(!isOpen)} style={{ marginBottom: '1rem' }}>
                Last Run Statistics
            </Button>
            {
                table.length > 0 ?
                <Collapse isOpen={isOpen}>
                    <h5>{JSON.parse(state['run_lock'])['lambda_name'] === 'remove_reports' ? 'Remove ' : 'Historical '}Run</h5>
                    <JsonToTable tbodyData={table}/>
                </Collapse>
                : null
            }
        </div>
    )
}

export default RunReportDetails;
