import {FormField} from "../input";
import {SettingsGroup} from "../settingsGroup";
import React, {useState} from "react";
import {Toggle} from "../toggle";
import {Button, Collapse} from "reactstrap";
import {MultiMapSelect} from "../singleSelect";
import {MultiSelectCreatable} from "../multiSelect";

export const CovaIncomeSettings = ({state, setState, qboAccount}) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <SettingsGroup groupName={<div id='INCOME'>Sales & Refund Receipts</div>}
                       reportType='INCOME'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable module. Creates receipts that holds sales summary for the day under register-named account. Summary: sales, discounts, rounding, non-revenue donations, cogs, tax (by type). Granularity: by register, day.'>
                <Toggle toggleName='Merge discounts into sales'
                        state={state}
                        setState={setState}
                        name='income_merge_discounts'
                        tooltipContent='Disables creation of dedicated Discounts item in sales receipt. Subtracts discounts sum from the Sales item. '/>
                <br></br>
                <Toggle toggleName='Sales breakdown by product category'
                        state={state}
                        setState={setState}
                        name='income_breakdown'/>
                <Toggle toggleName='COGS breakdown by product category'
                        state={state}
                        setState={setState}
                        name='income_cogs_breakdown'/>
                <Toggle toggleName='Discounts breakdown by product category'
                        state={state}
                        setState={setState}
                        name='income_discounts_breakdown'/>
                <br></br>
                <Toggle toggleName='Sales breakdown by cannabis'
                        state={state}
                        setState={setState}
                        name='income_sales_cannabis_breakdown'
                        tooltipContent='Make sure that ONLY cannabis products are set as batch-tracked in Cova admin panel.'/>
                <Toggle toggleName='COGS breakdown by cannabis'
                        state={state}
                        setState={setState}
                        name='income_cogs_cannabis_breakdown'/>
                <Toggle toggleName='Discounts breakdown by cannabis'
                        state={state}
                        setState={setState}
                        name='income_disc_cannabis_breakdown'/>
                <br></br>
                <FormField groupName='Sales Tracking Class'
                           formName='Tracking class for sales receipts'
                           placeholder='Default value: not classed'
                           name='income_tracking_class'
                           state={state}
                           setState={setState}/>
                <FormField groupName='Custom Tax Namings Map'
                           formName='Tax names mapping'
                           placeholder='Possible value: {"Sales Tax": "Sales Tax Payable"}'
                           name='income_tax_naming_map'
                           state={state}
                           setState={setState}
                           tooltipContent='Relation map. Can be used to rename taxes in QBO or assign several taxes from POS to a single QBO item in sales receipt.
                               Template: {"Sales Tax":"Sales Tax Payable","Excise Tax":”Excise Tax Liability"}'/>
                <FormField groupName='Sales & Returns'
                           formName='Customer name placeholder'
                           placeholder='Default value: *POS Sales Info'
                           name='sales_customer_name'
                           state={state}
                           setState={setState}/>
                <FormField groupName='Custom sales item naming'
                           formName='Custom sales item naming'
                           placeholder='Default value: Sales'
                           name='income_sales_line_name'
                           state={state}
                           setState={setState}/>
                <MultiSelectCreatable groupName='Fee Product Names'
                                      formName='Fee Product Names'
                                      name='income_fee_product_names'
                                      state={state}
                                      setState={setState}
                                      qboAccount={qboAccount}
                                      tooltipContent='List of product names that are used as indicators for fees and tips'/>
                <br></br>
                <Toggle toggleName='Include Debit Payments into Sales Receipt'
                        name='debit_to_income'
                        state={state}
                        setState={setState}
                        tooltipContent='Includes debit payments as an item line in the SalesReceipt'/>

                <Button color="success" onClick={() => setIsOpen(!isOpen)} style={{marginBottom: '1rem'}}>
                        Debit Lines Naming
                </Button>
                <Collapse isOpen={isOpen}>
                        <MultiMapSelect
                            settingName='debit_payment_types'
                            state={state}
                            setState={setState}/>
                </Collapse>
        </SettingsGroup>
    )
}