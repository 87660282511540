import {FormField} from "../input";
import {FhInventorySettingsGroup, SettingsGroup} from "../settingsGroup";
import React from "react";
import {InventoryCategorySelect, SingleSelect} from "../singleSelect";
import {Toggle} from "../toggle";
import {MultiSelectCreatable} from "../multiSelect";

export const FlowhubInventorySettings = ({state, setState, qboAccount, qboAccNames}) => {
    return (
        <SettingsGroup groupName={<div id='INVENTORY'>Vendor Bills</div>}
                       reportType='INVENTORY'
                       name='report_types'
                       state={state}
                       setState={setState}
                       tooltipContent='Turn on to enable QBO Bills. Module creates bills in the "expanses" section of the QBO. Granularity: total cost of goods in received inventory entry'>
            <FormField groupName='Vendor Tracking Class'
                       formName='Tracking class for vendor bills'
                       placeholder='Default value: Disabled'
                       name='inventory_tracking_class'
                       state={state}
                       setState={setState}/>
            <SingleSelect groupName='Vendor Bills'
                          formName='Inventory account name'
                          name='inventory_acc_name'
                          state={state}
                          setState={setState}
                          options={qboAccNames}/>
            <FormField groupName='Vendor Names Mapping'
                       formName='Vendor names mapping'
                       placeholder='Possible value: {"Vendor Original Name": "Vendor Custom Name"}'
                       state={state}
                       setState={setState}
                       name='inventory_vendor_naming_map'
                       tooltipContent='Relation map. Can be used to rename vendors in QBO or assign several vendor names from POS to a single QBO vendor.
                               Template: {"POS name 1":"QBO name", "POS name 2":" QBO name"}'/>
            <FormField groupName='Vendor Bills'
                       formName='Inventory account type'
                       placeholder='Default value: Other Current Asset'
                       state={state}
                       setState={setState}
                       name='inventory_acc_type'/>
            <FormField groupName='Vendor Bills'
                       formName='Payment Term'
                       placeholder='Default value: Net 30'
                       name='inventory_sales_term_standard'
                       state={state}
                       setState={setState}/>
            <MultiSelectCreatable
                groupName='Exclude Vendors'
                formName='Exclude Vendors Names'
                name='inventory_exclude_vendors'
                state={state}
                setState={setState}
                qboAccount={qboAccount}
                tooltipContent='List of vendor names to exclude from billing'/>
            <FhInventorySettingsGroup groupName='Category to Account Invoice Mapping'
                                     name='inventory_category_map'
                                     qboAccount={qboAccount}
                                     state={state}
                                     setState={setState}>
                <Toggle toggleName='Set Inventory Account Name as a Parent'
                        state={state}
                        setState={setState}
                        name='inventory_parent_category'/>
                <InventoryCategorySelect
                                         settingName='inventory_category_map'
                                         state={state}
                                         setState={setState}
                                         options={qboAccNames}/>
            </FhInventorySettingsGroup>
        </SettingsGroup>
    )
}