import {getUrl, userSettings, userSettingsCovaDemo} from "../docs/data";
import axios from "axios";
import {handleError} from "./apiErrorHandler";

export function getUserSettings() {
    const user_settings = sessionStorage.getItem('user_settings');
    if (!user_settings) {
        return userSettings;
    }
    else {
        let settings = JSON.parse(user_settings)
        if (settings.is_demo === true) settings = userSettingsCovaDemo
        return settings
    }
}

function getAccountSettings(qboAccount, settingName) {
    const userSettings = getUserSettings()
    if (userSettings[qboAccount] && userSettings[qboAccount][settingName]) {
        let setting = userSettings[qboAccount][settingName]
        return  typeof(setting) === 'object' ? JSON.stringify(setting) : setting
    }
    if (settingName === 'inventory_vendor_naming_map' || settingName === 'income_tax_naming_map') {
        return '{}'
    }
    return ''
}


function getHeader() {
    return {headers: {'csrf-token': sessionStorage.getItem('token'),
                        'username': sessionStorage.getItem('username')}}
}

function getSessionItem(item_name) {
    console.log('Getting session item ', item_name)
   let item = sessionStorage.getItem(item_name)
   if (item === 'undefined' || !item) {
        return '';
    } else {
        return item;
    }
}

function setUserSession(user_settings, header) {
    console.log('Setting session')
    if (user_settings) sessionStorage.setItem('user_settings', JSON.stringify(user_settings));
    sessionStorage.setItem('token', header['csrf-token']);
    sessionStorage.setItem('username', header['username']);
    // sessionStorage.setItem('subscription_type', header['subscription-type']);
}

function setSetting(name, value) {
    console.log('Setting item: ', name, value)
    sessionStorage.setItem(name, value);
}

function makeAuthCall() {
    console.log('Making auth call');
}

const logOutHandler = (login, setLogin) => {
    if (login) {
        console.log('Resetting session')
        sessionStorage.clear();
        setLogin(false)
    }
}

const getAccNames = (setAccountNames, setMessage, setVisible, setColor) => {
    console.log('Requesting account names')
    let header = getHeader()
    const url = getUrl('account_name')

    axios.get(url, header)
        .then(function (response) {
            if (response.status === 200) {
                setUserSession(undefined, response.headers)
                setAccountNames(response.data)
            }
        })
        .catch(error => {
            handleError(error, setMessage, setVisible, setColor)
        })
}

export {getAccountSettings, setSetting, setUserSession, getSessionItem, getHeader, logOutHandler, makeAuthCall, getAccNames};