import {FormField} from "../input";
import {UncontrolledSettingGroup} from "../settingsGroup";
import React from "react";
import {BasicSelector} from "../select";
import {accountingBasis, timeZones} from "../../docs/data";
import {FlowhubGlobalSettings} from "./FlowhubGlobalSettings";
import {CanixGlobalSettings} from "./CanixGlobalSettings";
import {CovaGlobalSettings} from "./CovaGlobalSettings";
import {SingleSelect} from "../singleSelect";


export const GlobalSettings = ({state, setState, qboAccNames}) => {
    if (state.integration_name === 'CANIX') return <CanixGlobalSettings state={state} setState={setState} qboAccNames={qboAccNames}/>
    if (state.integration_name === 'FLOWHUB') return <FlowhubGlobalSettings state={state} setState={setState} qboAccNames={qboAccNames}/>
    if (state.integration_name === 'COVA') return <CovaGlobalSettings state={state} setState={setState} qboAccNames={qboAccNames}/>

    const handleInput = (event) => {
        state['parent_acc_name_new'] = event.value
        setState({...state})
    }

    return (
        <UncontrolledSettingGroup groupName='Global Settings'>
            <SingleSelect groupName='Global Settings'
                formName='Registers Parent Bank Account Name'
                name='parent_acc_name'
                state={state}
                setState={setState}
                tooltipContent='This account will be parent to all register-dedicated accounts. In case of Lite mode - this will be the account name to hold all sales receipts and debit journals'
                options={qboAccNames}/>
            <FormField groupName='Location Name'
                       formName='Location Name'
                       placeholder='Las Vegas'
                       state={state}
                       setState={setState}
                       name='department_name'
                       tooltipContent='This is a Department name (LocationId) all qbo entries will attribure to'/>
            <BasicSelector formName='Time Zone'
                           name="pos_timezone"
                           componentName='TimezoneSelector'
                           state={state}
                           setState={setState}
                           items={timeZones}
                           tooltipContent='The one set in Dutchie account for this API key.'/>
            <BasicSelector formName='Accounting Basis'
                           name='accounting_basis'
                           componentName='AccountingBasis'
                           state={state}
                           setState={setState}
                           items={accountingBasis}/>
            <FormField groupName='Dutchie API Key'
                       formName='Dutchie API Key'
                       placeholder='32-figure key'
                       state={state}
                       setState={setState}
                       name='dutchie_key'
                       type='password'
                       tooltipContent='Example: 633ae2af94048bcg9d0b29d23490gc08'/>
        </UncontrolledSettingGroup>
    )
}